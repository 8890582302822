export const indicatorTracking = (
  currentStep = 0, //default is 1
  meshesForStep,
  currentWidthStepsRef
) => {
  let total = 0;
  let newMeshes = meshesForStep.filter((value) => {
    return value.length > 0
  })
  
  if (currentWidthStepsRef.current) {
    let allStepsWidth = currentWidthStepsRef.current.clientWidth;
    let allStepsLength = newMeshes.length || 1;
    let currentWidthStep = allStepsWidth / allStepsLength;
    let calcCurrentStep = currentWidthStep / 2-9 ;
    total = (currentWidthStep * currentStep) + calcCurrentStep;
    // console.log(currentStep, "currentStep");
    // console.log(allStepsWidth);
    // console.log(calcCurrentStep, "calcCurrentStep");
    // console.log(total, 'total');
  }
  return total;
};
