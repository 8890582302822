import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  resolvedData,
  howManyTsFiles,
  resolvePromise,
  generateStepsForMeshes,
} from "../../TS-Helper";
import loader from "../../TS-VIEWER/loader";
import { createScene, meshesForStep } from "../../TS-VIEWER/Scene";
import viewer from "../../TS-VIEWER/viewer";
import styles from "./lindor-style/lindor.module.scss";
import {
  updateMeshesAccordingToStepNumber,
  indicatorTracking
} from "../milkyway-template/milkyway-functionality/index";
import GenerateSteps from "./lindor-components/generate-steps";
import GenerateLabels from "../milkyway-template/milkyway-components/generate-labels";
import SideBar from "./lindor-components/side-bar";
import PlayPauseAnimate from "./lindor-components/animate-steps";
import ProgressBar from "./lindor-components/progress-bar";
import ViewerNotFound from "./lindor-components/ts-not-found";
import { Helmet } from "react-helmet";

function LindorTemplate(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const { caseId, tsId, doctorId, modalViewer, showBiteRecord, position, finished} = props;
  const tsContaner = useRef(null);
  const tsRef = useRef(null);
  const renderCanvas = useRef(null);
  const currentWidthStepsRef = useRef(null);
  
  const stepsRef = useRef([]);
  const rangeRef = useRef(null);
  
  const [tsOptions, setTSOptions] = useState({
    //default options
    STEPS_PAUSE: 500,
    showUpperArc: true,
    showLowerArc: true,
    showUpper: true, // for maxilla
    showLower: true, // for mandible
    loading: true,
    isAttachmentsFromParam: false, // check if this template toggled the attachments from the params url
    isAttachment: true, // for toggle attachments
    isTSViewerFound: true,
    isDarkMode: false,
    isAnimateStart: false,
    isSuperImpose: false,
    hasSuperImpose: true,
    isLoaded: false,
    isTsPrepared: false,
  });
  const [stepsOptions, setStepsOptions] = useState(0);
  const [zoom, setZoom] = useState(100)
  
  const [progress, setProgress] = useState(0);
  const [countProgress, setCountProgress] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [preveStep, setPreveStep] = useState(currentStep)
  const [rangeValue, setRangeValue] = useState(0)
  const allSteps = meshesForStep.length -1;
  const [indicatorWidth, setIndicatorWidth] = useState(
    indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
  );
  const handleZoomIn = () => {
    if (zoom < 200 ) {
        viewer.dollyIn(1)
        setZoom(zoom + 25)
    } else return;
}

const handleZoomOut = () => {
    if ( zoom > 25 ) {
        viewer.dollyOut(1)
        setZoom(zoom - 25)
    } else return;
}

  const handleScrollBefore = (step) => {
    if (preveStep === 0) {
      rangeRef.current.scrollTo({
        left: stepsRef.current[allSteps].offsetLeft, 
        behavior: "smooth",
      });
    } else {
      rangeRef.current.scrollTo({
        left: (stepsRef.current[step].offsetLeft) - 45, 
        behavior: "smooth",
      });
    }
  }
  const handleScroll = (step) => {
      rangeRef.current.scrollTo({
        left: (stepsRef.current[step].offsetLeft) , 
        behavior: "smooth",
      });

  }
  const handleScrollAfter = (step) => {
    console.log(stepsRef.current);
    if (preveStep === 0) {
      rangeRef.current.scrollTo({
        left: 8, 
        behavior: "smooth",
      });
    } else {
      rangeRef.current.scrollTo({
        left: (stepsRef.current[step].offsetLeft)+25, 
        behavior: "smooth",
      });
    }
  }

  let preparingTSViewer = useCallback(() => {
    resolvePromise(caseId, tsId, doctorId).then(
      resolver => {
        return resolvedData(resolver("manifest.json")).then(({data})=>{
        const lowerSteps = data["lowerStepsNumber"];
        const upperSteps = data["upperStepsNumber"];
        const startTogether = data["startTogether"];
        let passiveAligners = data["passiveAligners"];
        let upperOvercorrectionStepsNumber =
          data["upperOvercorrectionStepsNumber"];
        let lowerOvercorrectionStepsNumber =
          data["lowerOvercorrectionStepsNumber"];
        if (lowerSteps < upperSteps) {
          // override lowerOvercorrectionStepsNumber
          lowerOvercorrectionStepsNumber = passiveAligners
            ? 0
            : data["lowerOvercorrectionStepsNumber"];
            
        } if (upperSteps < lowerSteps) {
          // override lowerOvercorrectionStepsNumber
          upperOvercorrectionStepsNumber = passiveAligners
            ? 0
            : data["upperOvercorrectionStepsNumber"];
        }
  
        setStepsOptions({
          lowerSteps,
          upperSteps,
          startTogether,
          passiveAligners,
          lowerOvercorrectionStepsNumber,
          upperOvercorrectionStepsNumber,
        });
        let howManyFiles = howManyTsFiles(data, true, tsOptions.hasSuperImpose);
        setCountProgress(howManyFiles);
        generateStepsForMeshes(lowerSteps, upperSteps);
        let scene = createScene(lowerSteps, upperSteps, startTogether);
        return loader(
          resolver,
          data,
          scene,
          setProgress,
          setCountProgress,
          setTSOptions,
          tsOptions.isAttachmentsFromParam,
          tsOptions.hasSuperImpose
        );
        }).then(()=>{
          updateMeshesAccordingToStepNumber(currentStep, tsOptions);
          
          setTSOptions((prevTSOptions) => ({
            ...prevTSOptions,
            isTsPrepared: false,
            loading: false,
            isLoaded: true,
          }));
          
          viewer.initializeAndRun(renderCanvas.current, {
            action: "lindor-template",
          });
        })
      } 
    )
    .catch((err)=>{
      //  Treatment plan not found
        setTSOptions((prevTSOptions) => ({
          ...prevTSOptions,
          loading: false,
          isTSViewerFound: false,
          isTsPrepared: false,
          isLoaded: true,
        }));
        console.log(err, "Treatment plan not found"); 
    })
  },[caseId, tsId, doctorId])

  const handleResizeContainer = () => {
    setIndicatorWidth(
      indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
    );
  };

  useEffect(() => {
    setIndicatorWidth(
      indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
    );
  }, [currentStep, meshesForStep.length, props.collapse]);

  useEffect(() => {
    window.addEventListener("resize", handleResizeContainer);
    window.addEventListener("resize", viewer.zoomValue);
  }, []);

  useEffect(() => {
      if (!tsOptions.isLoaded) {
          preparingTSViewer();
      }
  }, [tsOptions.isLoaded]);

  useEffect(() => {
    if (modalViewer === false) {
      // global.gc()
      viewer.resetZoom()
    }
    if (meshesForStep.length) {
      updateMeshesAccordingToStepNumber(currentStep, tsOptions);
    }
  }, [
    currentStep,
    tsOptions.showUpperArc,
    tsOptions.showLowerArc,
    tsOptions.showUpper,
    tsOptions.showLower,
    tsOptions.isAttachment,
    tsOptions.isSuperImpose,
    tsOptions.isAnimateStart,
    modalViewer
  ]);

  useEffect(() => {
    return () => {
      meshesForStep.filter(() => {
        return meshesForStep.splice(0);
      })
    }
  }, [])
  return (
    <div className={styles.container}  
    onWheel={(event) => {
      // zoom in / out on wheel
      if (event.deltaY > 0) {
        handleZoomOut()
      }
      if (event.deltaY < 0) {
        handleZoomIn()
      }
    }}
   >
      <Helmet>
        {/* disable zoom on mobile web page */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0"
        />
        <title>Zenyum viewer</title>
      </Helmet>
      <div className={styles.thewiewerwindow} ref={tsRef}>
      <img
          src={`${process.env.REACT_APP_IMAGE_URL}/lindor-imgs/Zenyum_Logo.png`}
          alt=""
          className={styles.eon_access_logo}/>
         <SideBar
            tsOptions={tsOptions}
            setTSOptions={setTSOptions}
      />
        <canvas className={styles.rendercanvas} ref={renderCanvas}></canvas>

        {/* Progress bar  */}
        <ProgressBar
          loading={tsOptions.loading}
          progress={progress}
          countProgress={countProgress}
          isTsPrepared={tsOptions.isTsPrepared}
        />

        {/* Not found viwer  */}

        <ViewerNotFound isTSViewerFound={tsOptions.isTSViewerFound} />

        {/* <!-- Steps container --> */}
        <div
          ref={rangeRef}
          className={styles.steps_container}
          style={
            tsOptions.loading ||
            tsOptions.isTsPrepared ||
            !tsOptions.isTSViewerFound
              ? { zIndex: "-1" }
              : { zIndex: "1" }
          }
        >
          <div className={styles.steps_content}>
            {/* <!-- Generate upper steps --> */}
            <GenerateSteps
              isTsPrepared={tsOptions.isTsPrepared}
              isLoaded={tsOptions.isLoaded}
              isTSViewerFound={tsOptions.isTSViewerFound}
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              lowerSteps={stepsOptions.lowerSteps}
              upperSteps={stepsOptions.upperSteps}
              allStepsForUpperOrLower={stepsOptions.upperSteps} // all upper steps
              startTogether={stepsOptions.startTogether}
              passiveAligners={stepsOptions.passiveAligners}
              overCorrectionStepsNumber={
                stepsOptions.upperOvercorrectionStepsNumber // overcorrection for upper steps
              }
              currentStep={currentStep}
              stepsRef={stepsRef}
              preveStep={preveStep}
              setPreveStep={setPreveStep}
              isAnimateStart={tsOptions.isAnimateStart}
              handleScroll={handleScroll}
            />
          </div>

          <div className={styles.steps_content}>
            {/* <!-- Generate lower steps --> */}
            <GenerateSteps
              isTsPrepared={tsOptions.isTsPrepared}
              isLoaded={tsOptions.isLoaded}
              isTSViewerFound={tsOptions.isTSViewerFound}
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              lowerSteps={stepsOptions.lowerSteps}
              upperSteps={stepsOptions.upperSteps}
              allStepsForUpperOrLower={stepsOptions.lowerSteps} // all lower steps
              startTogether={stepsOptions.startTogether}
              passiveAligners={stepsOptions.passiveAligners}
              overCorrectionStepsNumber={
                stepsOptions.lowerOvercorrectionStepsNumber // overcorrection for lower steps
              }
              currentStep={currentStep}
              stepsRef={stepsRef}
              preveStep={preveStep}
              setPreveStep={setPreveStep} 
              isAnimateStart={tsOptions.isAnimateStart}
              handleScroll={handleScroll}
            />
          </div>
          <div className={styles.labels_content} ref={currentWidthStepsRef}>
            <div
              className={styles.tooltip_orange}
              style={
               
                   {
                      left: indicatorTracking(
                        currentStep,
                        meshesForStep,
                        currentWidthStepsRef
                      ),
                    }
              }
            ></div>
            <div
              className={styles.tooltip_white}
              style={
             
                  
                  {
                      left:
                        indicatorTracking(
                          currentStep,
                          meshesForStep,
                          currentWidthStepsRef
                        ) + 3,
                    }
              }
            ></div>
            <GenerateLabels
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              isDarkMode={tsOptions.isDarkMode}
              isTsPrepared={tsOptions.isTsPrepared}
            />
          </div>
        </div>

        {/* <!-- Play/Pause animate --> */}
        <PlayPauseAnimate
          tsOptions={tsOptions}
          setTSOptions={setTSOptions}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          meshesForStep={meshesForStep}
          stepsRef={stepsRef}
          setPreveStep={setPreveStep}
          preveStep={preveStep}
          handleScroll={handleScroll}
          handleScrollBefore={handleScrollBefore}
          handleScrollAfter={handleScrollAfter}
        />
      </div>
    </div>
  );
}

export default LindorTemplate;
