import React from "react";

import { stepsStyleValidation } from "../../milkyway-template/milkyway-functionality/index";
export default function GenerateSteps(props) {
  const {
    meshesForStep,
    setCurrentStep,
    loading,
    isTsPrepared,
    lowerSteps,
    upperSteps,
    allStepsForUpperOrLower,
    startTogether,
    passiveAligners,
    overCorrectionStepsNumber,
    currentStep,
    isLoaded,
    isTSViewerFound,
    stepsRef,
    handleScroll
  } = props;
  let maxStepNumber = meshesForStep.length - 1;

  const addToRefs = (elem) => {
    if (isLoaded && isTSViewerFound) {
      if(elem && !stepsRef.current.includes(elem)) {
        stepsRef.current.push(elem)
      }
    }
  }

  return meshesForStep
    ? meshesForStep.map((step, index) => {
        return (
          <div
            className={stepsStyleValidation(
              index,
              maxStepNumber,
              lowerSteps,
              upperSteps,
              allStepsForUpperOrLower,
              startTogether,
              passiveAligners,
              overCorrectionStepsNumber
            )}
            ref={addToRefs}
            key={`${index}`}
            onClick={() => {
              if (loading || isTsPrepared) return;
              setCurrentStep(index);
              handleScroll(index)
            }}
          ></div>
        );
      })
    : null;
}
