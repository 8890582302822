import React from "react";
import styles from "../lindor-style/lindor.module.scss";
import {
  moveToFirstStep,
  moveBackword,
  moveForward,
  moveToLastStep,
} from "../../shared-functionalities";
import play from "../../../images/bounty-imgs/play.svg";
import pause from "../../../images/bounty-imgs/pause.svg";
import back from "../../../images/bounty-imgs/backward.svg";
import next from "../../../images/bounty-imgs/fast-forward.svg";
import first from "../../../images/bounty-imgs/previous.svg";
import last from "../../../images/bounty-imgs/skip.svg";
let intervalId;

export default function AnimateSteps(props) {
  const {
    tsOptions,
    setTSOptions,
    currentStep,
    setCurrentStep,
    meshesForStep,
    setPreveStep,
    handleScroll,
    handleScrollBefore,
    handleScrollAfter
  } = props;
  let allSteps = meshesForStep.length - 1
  let animateStep = () => {
    let num = currentStep === meshesForStep.length - 1 ? 0 : currentStep;
    let previousStep = 0;
    intervalId = setInterval(() => {
      if (num === meshesForStep.length - 1) {
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          isAnimateStart: !prevOptions.isAnimateStart,
        }));
        setPreveStep(meshesForStep.length - 1)
        num = 0;
        clearInterval(intervalId);
        return;
      }
      if (num === 0) {
        setCurrentStep((prevStep) => {
          previousStep = prevStep;
          return num;
        });
      }
      setCurrentStep((pervStep) => {
        if (pervStep !== num) {
          // console.log("chcek nummmmm ", pervStep, num);
          setTSOptions((prevOptions) => ({
            ...prevOptions,
            isAnimateStart: !prevOptions.isAnimateStart,
          }));
          clearInterval(intervalId);
          return pervStep;
        }
        num += previousStep === meshesForStep.length - 1 ? 0 : 1;
        previousStep = num === 0 ? num : num - 1;
        return num;
      });
      
          handleScroll(num)
     
    }, tsOptions.STEPS_PAUSE || 500);
  };
  return (
    <React.Fragment>
      {/* <!-- Play/Pause animate only on mobile/device--> */}
      <div
        className={styles.play_pause_container}
        style={
          tsOptions.loading ||
          tsOptions.isTsPrepared ||
          !tsOptions.isTSViewerFound
            ? { zIndex: "-1" }
            : { zIndex: "1" }
        }
      >
        <div 
        className={styles.btn}
          onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return;
            handleScroll(0)
            moveToFirstStep(currentStep, setCurrentStep, setPreveStep);
        }}>
          <img src={first} style={{width: "95%", margin: "auto", display: "block"}}/>
        </div>
        <div 
        className={styles.btn}
          onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return;
            handleScrollBefore(currentStep)
            moveBackword(currentStep, setCurrentStep, meshesForStep.length - 1, setPreveStep);
        }}>
          <img src={back} style={{width: "95%", margin: "auto", display: "block"}}/>
        </div>

        <div className={styles.play_pause_icon}>
          <img
          style={{width: "95%", margin: "auto", display: "block"}}
            src={
              tsOptions.isAnimateStart
                ? pause
                : play
            }
            alt=""
            onClick={ () =>{ if (tsOptions.loading || tsOptions.isTsPrepared) return;
              let isAnimateStart = !tsOptions.isAnimateStart;
              if (isAnimateStart) {
                animateStep();
              } else {
                clearInterval(intervalId);
                setPreveStep(currentStep)
                // console.log({preveStep, currentStep});
              }
              setTSOptions((prevOptions) => ({
                ...prevOptions,
                isAnimateStart: isAnimateStart,
            }));}}
          />
        </div>

        <div 
        className={styles.btn_after}
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          handleScrollAfter(currentStep)
          moveForward(currentStep, setCurrentStep, meshesForStep.length - 1, setPreveStep);
        }}>
          <img src={next} style={{width: "95%", margin: "auto", display: "block"}}/>
        </div>
        <div 
        className={styles.btn_after}
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          handleScroll(allSteps)
          moveToLastStep(currentStep, meshesForStep.length - 1, setCurrentStep, setPreveStep);
        }}>
          <img src={last} style={{width: "95%", margin: "auto", display: "block"}}/>
        </div>
      </div>
    </React.Fragment>
  );
}
