import * as THREE from "three";

//eventually create Constants module
// const EonShapeConstants = {
//   GUMS: 1,
//   TOOTH_START: 2,
//   ATTACHMENTS_START: 45,
// };

const createGeometry = /*EonGumsGeometry*/ function (
  /*MasterFile*/ masterFile
) {
  let /*Uint32Array*/ _indices = masterFile.body.indices;
  let /*Float32Array*/ _vertices = masterFile.body.vertices;
  let /*Float32Array*/ _normals = masterFile.smoothNormals;
  let /*Float32Array*/ _colors = masterFile.colors;

  let /*JsArray<Offset>*/ offsets = masterFile.offsets;

  /* EonGumsGeometry  geometry = new EonGumsGeometry(); */
  let geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(_vertices, 3));
  geometry.setAttribute("normal", new THREE.BufferAttribute(_normals, 3));
  //geometry.setAttribute( "index", new BufferAttribute( _indices, 3 ) );
  geometry.setAttribute("color", new THREE.BufferAttribute(_colors, 3));

  //rearrange indices for the lack of drawCalls in current threejs

  if (offsets /*!= null*/) {
    let rei = new Uint32Array(_indices.length);

    for (let j = 0; j < offsets.length; j++) {
      let /*Offset*/ offset = offsets[j];
      //console.log('offset ', j, 'starting at', offset.start, 'with a shift of', offset.index)
      for (let i = 0; i < offset.count; i++) {
        rei[i + offset.start] = _indices[i + offset.start] + offset.index;
      }
    }
    geometry.setIndex(new THREE.BufferAttribute(rei, 1));
  } else {
    geometry.setIndex(new THREE.BufferAttribute(_indices, 1));
  }

  //console.log(geometry)
  return geometry;
};

let gumsCallback = function (
  decompressed,
  g_range_start,
  g_range_end,
  color_table,
  scene
) {
  const p = new Promise((resolve, reject) => {
    //console.log("parsed gums", gumsParser);

    let gs = [createGeometry(decompressed)];

    //console.log("GS", gs)
    for (let i in gs) {
      let x = Number(i);
      let g = gs[i];

      //let material = new THREE.MeshBasicMaterial( { color: 0xffffff , side: THREE.DoubleSide} );
      let material = new THREE.MeshPhysicalMaterial({
        color: '#ff6161',
        flatShading: false,
        side: THREE.DoubleSide,
        metalness: 0.1,
        roughness: 1,
        clearcoat: 0.1,
        emissive: '#383838',
        emissiveIntensity: 1,
        clearcoatRoughness: 0.3
      });

      //var material = new THREE.MeshPhongMaterial({color: 0xfafafa});
      let mesh = new THREE.Mesh(g, material);

      for (let index = g_range_start; index < g_range_end; index++) {
        scene.addGums(x + index, mesh);
      }

      //console.log("gums ", i, 'at pos', (x + g_range_start))
    }
    resolve("gums ok");
  });

  return p;
};

export default { parse: gumsCallback };
