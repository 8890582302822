import React, { useState } from "react";
import viewer from "../../../TS-VIEWER/viewer";
//SVG imgs
import  upper from "../../../images/bounty-imgs/upper.svg";
import  lower from "../../../images/bounty-imgs/lower.svg";
import  right from "../../../images/bounty-imgs/right.svg";
import  left from "../../../images/bounty-imgs/left.svg";
import  front from "../../../images/bounty-imgs/front.svg";
import  upper_active from "../../../images/bounty-imgs/upper_active.svg";
import  lower_active from "../../../images/bounty-imgs/lower_active.svg";
import  right_active from "../../../images/bounty-imgs/right_active.svg";
import  left_active from "../../../images/bounty-imgs/left_active.svg";
import  front_active from "../../../images/bounty-imgs/front_active.svg";
import styles from "../lindor-style/lindor.module.scss";
export default function SideBar(props) {
  const {
    tsOptions,
    setTSOptions,
} = props;

const [viewActiveTs, setViewActiveTs] = useState("front");

const onTsViewChange = (action) => {
  switch (action.viewActiveTs) {
    case "left-view":
      if (!tsOptions.isTSViewerFound) return;
      setViewActiveTs("left");
      setTSOptions((prevOptions) => ({
        ...prevOptions,
        showLowerArc: true,
        showUpperArc: true,
        showUpper: true,
        showLower: true,
      }));
      viewer.leftView();
      break;

    case "right-view":
      if (!tsOptions.isTSViewerFound) return;
      setViewActiveTs("right");
      setTSOptions((prevOptions) => ({
        ...prevOptions,
        showLowerArc: true,
        showUpperArc: true,
        showUpper: true,
        showLower: true,
      }));
      viewer.rightView();
      break;
    case "front-view":
      if (!tsOptions.isTSViewerFound) return;
      setViewActiveTs("front");
      setTSOptions((prevOptions) => ({
        ...prevOptions,
        showLowerArc: true,
        showUpperArc: true,
        showUpper: true,
        showLower: true,
      }));
      viewer.frontView();
      break;
    case "upper-view":
      if (!tsOptions.isTSViewerFound) return;
      setViewActiveTs("upper");
      setTSOptions((prevOptions) => ({
        ...prevOptions,
        showLowerArc: true,
        showUpperArc: false,
        showUpper: false,
        showLower: true,
      }));
      viewer.upperView();
      break;
    case "lower-view":
      if (!tsOptions.isTSViewerFound) return;
      setViewActiveTs("lower");
      setTSOptions((prevOptions) => ({
        ...prevOptions,
        showLowerArc: false,
        showUpperArc: true,
        showUpper: true,
        showLower: false,
      }));
      viewer.lowerView();
      break;
    case "attachments-view":
      if (!tsOptions.isTSViewerFound) return;
      setTSOptions((prevOptions) => {
        return { ...prevOptions, isAttachment: !prevOptions.isAttachment };
      });
      break;

    case "superImpose-view":
      if (!tsOptions.isTSViewerFound) return;
      setTSOptions((prevOptions) => {
        return { ...prevOptions, isSuperImpose: !prevOptions.isSuperImpose };
      });
      break;
    default:
      if (!tsOptions.isTSViewerFound) return;
      setViewActiveTs("front");
      setTSOptions((prevOptions) => ({
        ...prevOptions,
        showLowerArc: true,
        showUpperArc: true,
      }));
      viewer.frontView();
  }
};



  return (
    <div className={styles.sid_bar_container}>
      <div>
        <div
          className={styles.frontView }
          onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return;
            onTsViewChange({ viewActiveTs: "front-view" });
          }}
        >
        <img src={viewActiveTs ==="front" ? front_active : front} style={{width: "60px"}}/>
        </div>

        <div
          className={styles.upperView }
          onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return
            console.log(tsOptions);
            onTsViewChange({ viewActiveTs: "upper-view" });
          }}
        >
          <img src={viewActiveTs === "upper" ? upper_active : upper} style={{width: "60px"}}/>

        </div>

        <div
         className={styles.lowerView }
          onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return
            onTsViewChange({ viewActiveTs: "lower-view" });
          }}
        >
          <img src={viewActiveTs ==="lower" ? lower_active : lower} style={{width: "60px"}}/>
        </div>
        <div
         className={styles.leftView }
          onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return
            onTsViewChange({ viewActiveTs: "left-view" });
          }}
        >
          <img src={viewActiveTs ==="left" ? left_active : left} style={{width: "35px"}}/>
        </div>
        <div
         className={styles.rightView }
          onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return
            onTsViewChange({ viewActiveTs: "right-view" });
          }}
        >
        <img src={viewActiveTs ==="right" ? right_active : right} style={{width: "35px"}}/>
        </div>
        <button
         className={tsOptions.isAttachment ? styles.attachment_active : styles.attachment}
          onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return
            onTsViewChange({ viewActiveTs: "attachments-view" })
          }}
        >
          Attachments
        </button>

        
      </div>
    </div>
  );
}
