import React from "react";
import MilkywayTemplate from "./TS/Templates/milkyway-template";
import LindorTemplate from "./TS/Templates/lindor-template";
import BaklavaTemplate from "./TS/Templates/baklava-template";

function App() {
  return <LindorTemplate />;
}

export default App;
