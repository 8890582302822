import axios from "axios";

//const eonHeaders = new Headers();

const Axios = axios.create({
  baseURL: process.env.REACT_APP_TS_BASE_URL,
  //headers: eonHeaders,
});

const fetchTSUrls = (viewerId) => {
  return Axios.get(`/peep/treatmentSetupFiles/${viewerId}`);
};
// const fetchTSUrls = (viewerId) => {
//   return axios.get(`http://10.1.0.205:3002/getTreatmentSetup/${viewerId}`);
// };

export { fetchTSUrls };
