import * as THREE from "three";
//eventually create Constants module
const EonShapeConstants = {
  GUMS: 1,
  TOOTH_START: 2,
  ATTACHMENTS_START: 45,
};

let Mesh = function (geom, mtl) {
  this.geom = geom;
  this.mtl = mtl;
  this.parts = [];
  this.name = "";

  this.add = function (part) {
    //console.error(name, attr);
    //console.error(this.attributes)
    this.parts.push(part);
  };

  this.setName = function (name) {
    this.name = name;
  };

  this.extracted = false;
};

//Constructor
/*private static class*/
function ProtoGeometrty(size) {
  // let /*Float32Array*/ vertices;
  // let /*Float32Array*/ normals;
  // let offset;
  // let minY = +Number.MAX_VALUE;
  // let maxY = -Number.MAX_VALUE;

  //public ProtoGeometrty(int size) {
  this.vertices = new Float32Array(size);
  this.normals = new Float32Array(size);
  this.offset = 0;
  //}
}

/*HashMap<Integer, TeethBufferGeomatry> */
function classify(/*MasterFile*/ masterFile) {
  let /*Uint32Array*/ _indices = masterFile.body.indices;
  let /*Float32Array*/ _vertices = masterFile.body.vertices;
  let /*Float32Array*/ _normals = masterFile.smoothNormals;

  /*HashMap<Integer, ProtoGeometrty>*/
  let protoGeometries = []; //new HashMap<Integer, ProtoGeometrty>();

  //consume a whole face, i.e. 3 indices
  for (let i = 0; i < _indices.length; i += 3) {
    let attrInt = EonShapeConstants.ATTACHMENTS_START;
    let /*ProtoGeometrty*/ protoGeometry = protoGeometries[attrInt];
    if (!protoGeometry) {
      protoGeometry = new ProtoGeometrty(_indices.length * 3); //upper bound
      protoGeometries[attrInt] = protoGeometry;
    }

    for (let vIdx = 0; vIdx < 3; vIdx++) {
      let p = _indices[i + vIdx];
      let idx = p * 3;

      let x = _vertices[idx];
      let y = _vertices[idx + 1];
      let z = _vertices[idx + 2];

      let nx = _normals[idx];
      let ny = _normals[idx + 1];
      let nz = _normals[idx + 2];

      //is a gum if the attribute is ==GUMS for a vertex

      let offset = protoGeometry.offset;
      protoGeometry.vertices[offset] = x;
      protoGeometry.vertices[offset + 1] = y;
      protoGeometry.vertices[offset + 2] = z;

      protoGeometry.normals[offset] = nx;
      protoGeometry.normals[offset + 1] = ny;
      protoGeometry.normals[offset + 2] = nz;
      protoGeometry.offset += 3;
    }
  }

  //GWT.log("skipped" + strangeFaces + " strange faces");

  let /*HashMap<Integer, BufferGeometry>*/ geometries = []; //new HashMap<Integer, BufferGeometry>();

  for (let /*Entry<Integer, ProtoGeometrty>*/ index in protoGeometries) {
    let /*BufferGeometry*/ geometry = new THREE.BufferGeometry();

    let e = protoGeometries[index];

    let offset = e.offset;
    //Window.alert("pg: " + index + " elems: " + e.offset );
    let /*Float32Array*/ v = new Float32Array(offset);
    let /*Float32Array*/ n = new Float32Array(offset);

    let /*Float32Array*/ normals = e.normals;
    let /*Float32Array*/ vertices = e.vertices;
    for (let i = 0; i < offset; i++) {
      n[i] = normals[i];
      v[i] = vertices[i];
    }

    geometry.setAttribute("position", new THREE.BufferAttribute(v, 3));

    geometry.setAttribute("normal", new THREE.BufferAttribute(n, 3));

    geometries[index] = geometry;
    e.vertices = null;
    e.normals = null;
  }
  //protoGeometries.clear();

  return geometries;
}

/* EonAttachmentsGeometry */
function segment(/*MasterFile*/ masterFile) {
  var /*EonAttachmentsGeometry, an ArrayList*/ attachments = []; /*new EonAttachmentsGeometry();*/

  var /*HashMap<Integer, BufferGeometry>*/ components = classify(masterFile);

  for (
    let i = EonShapeConstants.ATTACHMENTS_START;
    i < EonShapeConstants.ATTACHMENTS_START + 45;
    i++
  ) {
    /*BufferGeometry*/
    var c = components[i];
    if (c) {
      attachments.push(c);
    }
  }

  return attachments;
}

/*Mesh*/
var makeAttachmentsMesh = function (
  /*EonAttachmentsGeometry*/ eonAttachmentsGeometry
) {
  let /*Mesh*/ eonMesh = new Mesh();
  let num = 0;

  for (/*BufferGeometry*/ let index in eonAttachmentsGeometry) {
    let a = eonAttachmentsGeometry[index];

    let /*MeshLambertMaterial*/ material = {
        name: "ATTACHMENTS MATERIAL",
      }; /*new MeshLambertMaterial()*/
    //material.setShading(Material.SHADING.SMOOTH);
    //material.setColor(attachmentSphericalColor);
    let /*Mesh*/ m = new Mesh(a, material);
    m.setName("attachment:" + num);
    eonMesh.add(m);
    num++;
  }
  return eonMesh;
};

var /* MasterFile*/ __computeFromEmbeddedDelta = function (
    /*MasterFile*/ masterFile,
    /*int*/ deltaNum
  ) {
    let /*Float32Array*/ deltas =
        masterFile.body.attrMaps[deltaNum]
          .attr; /* masterFile.getAttribute(deltaNum);*/
    return __computeDelta(masterFile, deltas);
  };

var computeNormals = function (indices, vertices) {
  var smooth = new Float32Array(vertices.length),
    indx,
    indy,
    indz,
    nx,
    ny,
    nz,
    v1x,
    v1y,
    v1z,
    v2x,
    v2y,
    v2z,
    len,
    i,
    k;

  for (i = 0, k = indices.length; i < k; ) {
    indx = indices[i++] * 3;
    indy = indices[i++] * 3;
    indz = indices[i++] * 3;

    v1x = vertices[indy] - vertices[indx];
    v2x = vertices[indz] - vertices[indx];
    v1y = vertices[indy + 1] - vertices[indx + 1];
    v2y = vertices[indz + 1] - vertices[indx + 1];
    v1z = vertices[indy + 2] - vertices[indx + 2];
    v2z = vertices[indz + 2] - vertices[indx + 2];

    nx = v1y * v2z - v1z * v2y;
    ny = v1z * v2x - v1x * v2z;
    nz = v1x * v2y - v1y * v2x;

    len = Math.sqrt(nx * nx + ny * ny + nz * nz);
    if (len > 1e-10) {
      nx /= len;
      ny /= len;
      nz /= len;
    }

    smooth[indx] += nx;
    smooth[indx + 1] += ny;
    smooth[indx + 2] += nz;
    smooth[indy] += nx;
    smooth[indy + 1] += ny;
    smooth[indy + 2] += nz;
    smooth[indz] += nx;
    smooth[indz + 1] += ny;
    smooth[indz + 2] += nz;
  }

  for (i = 0, k = smooth.length; i < k; i += 3) {
    len = Math.sqrt(
      smooth[i] * smooth[i] +
        smooth[i + 1] * smooth[i + 1] +
        smooth[i + 2] * smooth[i + 2]
    );

    if (len > 1e-10) {
      smooth[i] /= len;
      smooth[i + 1] /= len;
      smooth[i + 2] /= len;
    }
  }

  return smooth;
};

/*private static native MasterFile*/
let __computeDelta = function (
  /*MasterFile*/ masterFile,
  /*Float32Array*/ deltas
) {
  var base_vertices = masterFile.body.vertices;
  var new_vertices = new Float32Array(base_vertices.length);

  var num_vertices = base_vertices.length / 3;
  for (var i = 0; i < num_vertices; i++) {
    var vn = i * 3;
    var dn = i * 4; //deltas are shifted by one cause attributes are 4Dimensional
    new_vertices[vn] = base_vertices[vn] + deltas[dn + 1];
    new_vertices[vn + 1] = base_vertices[vn + 1] + deltas[dn + 2];
    new_vertices[vn + 2] = base_vertices[vn + 2] + deltas[dn + 3];
  }

  var o = {
    header: masterFile.header,
    body: {
      attrMaps: masterFile.body.attrMaps,
      indices: masterFile.body.indices,
      vertices: new_vertices,
    },
  };

  o.smoothNormals = computeNormals(o.body.indices, o.body.vertices);

  return o;
};

var computeNthAttachmentsGeometry = function (n, theMasterFile) {
  let /*MasterFile*/ m = __computeFromEmbeddedDelta(theMasterFile, n);
  return segment(m);
};

var createMeshes = function (
  masterFile,
  /*EonXHRLoader<EonTeethGeometry>*/ /* loader,*/ /*EonTeethGeometry*/ geometry,
  from,
  to
) {
  /*ArrayList<Mesh>*/
  let meshes = []; //new ArrayList<Mesh>();

  //this is what we find inside the file
  //but the number of attributes inside the file is unreliable
  //to determine the number of deltas
  //being at least 1
  //int nd = attachmentsLoader.getNumDeltas();

  let nd = to - from - 1; // minus 1 because one is the master file, here we measure deltas

  //console.error("--->",nd);

  let /*Mesh*/ mb = makeAttachmentsMesh(geometry);

  //console.error("mb", mb);

  meshes.push(mb);
  //progressBar.bindTeeth(from,mb);

  for (let i = 0; i < nd; i++) {
    let /*EonAttachmentsGeometry*/ eonGeometry = computeNthAttachmentsGeometry(
        i,
        masterFile
      );
    let /*Mesh*/ m = makeAttachmentsMesh(eonGeometry);
    meshes.push(m);
    //progressBar.bindAttachments(from+i+1,m);
  }

  //bus.fireEvent(new LoadingEvent(statusEventTag, "", StatusType.END));

  return meshes;
};

let attachmentsCallback = function (
  decompressed,
  a_range_start,
  a_range_end,
  color_table,
  scene
) {
  const p = new Promise((resolve, reject) => {
    let segmented = segment(decompressed);
    let res = createMeshes(decompressed, segmented, a_range_start, a_range_end);
    //console.log('attachments', res);

    for (let i in res) {
      let x = Number(i);
      let one = res[i].parts[0].geom;

      //console.log(one)

      //0x969497
      let material = new THREE.MeshPhysicalMaterial({
        color: "#1BABE2",
        flatShading: false,
        side: THREE.DoubleSide,
        metalness: 0,
        roughness: 1,
        clearcoat: 0.1,
        emissive: '#525252',
        emissiveIntensity: 0.5,
        clearcoatRoughness: 0.3,
      });
      let mesh = new THREE.Mesh(one, material);

      //questo e' dove la colloca
      //TODO
      /*
                if (!mymeshes[x + a_range_start])
                    mymeshes[x + a_range_start] = [];
                mymeshes[x + a_range_start].push(mesh)
                */
      scene.addAttachments(x + a_range_start, mesh);

      //console.log("attachment ", i, 'at pos', (x + a_range_start))
    }

    resolve("attachment ok");
  });

  //console.log("p0", p);

  return p;
};

export default { parse: attachmentsCallback };
