import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";


let controls;



let cameraZoom = 5.5
if(window.innerWidth > 1900) {
  cameraZoom = 8
}
let zoomValue = function () {
  if(window.innerWidth > 1900) {
    cameraZoom = 8;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
  } else {
    cameraZoom = 5.5;
    camera.zoom = cameraZoom;
    camera.updateProjectionMatrix();
  }
}

const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.01, 1000);
const scene = new THREE.Scene();

//lights
const color = 0xffffff;
const intensity = 1.7;
const light = new THREE.DirectionalLight(color, intensity);
light.position.set(0.25, 3, -2.25);

//sets camera to front view;
camera.position.z = 60;
camera.position.y = 0;
camera.position.x = 0;
camera.zoom = cameraZoom;

let frontView = function () {
  if(!controls) return;
  // controls.enabled = true;
  // controls.reset();
  camera.position.z = 60;
  camera.position.y = 0;
  camera.position.x = 0;
  camera.zoom = cameraZoom;
  camera.updateProjectionMatrix();
  controls.update();
};

let leftView = function () {
  // controls.resetKeepZoom();
  camera.position.z = 60;
  camera.position.y = 0;
  camera.position.x = 180;
  camera.zoom = cameraZoom;
  camera.updateProjectionMatrix();
  controls.update();
};

let rightView = function () {
  // controls.resetKeepZoom();
  camera.position.z = 60;
  camera.position.y = 0;
  camera.position.x = -180;
  camera.zoom = cameraZoom;
  camera.updateProjectionMatrix();
  controls.update();
};

let upperView = function () {
  // controls.resetKeepZoom();
  camera.position.z = 0;
  camera.position.y = -61.8;
  camera.position.x = 0;
  controls.update();
};

let lowerView = function () {
  // controls.resetKeepZoom();
  camera.position.z = 0;
  camera.position.y = 61.8;
  camera.position.x = 0;

  controls.update();
};


let frontRevise = function() {
  camera.position.z = 60;
  camera.position.y = 0;
  camera.position.x = 0;
  camera.zoom = 6;
  // controls.enableZoom = false;
  // controls.enableRotate = false;
  // controls.enablePan = false;
  toggleCamera();
}

let leftRevise = function() {
  camera.position.z = 60;
  camera.position.y = 15;
  camera.position.x = 180;
  camera.zoom = 6;
  // controls.enableZoom = false;
  // controls.enableRotate = false;
  // controls.enablePan = false;
  toggleCamera();
}

let rightRevise = function() {
  camera.position.z = 60;
  camera.position.y = 15;
  camera.position.x = -180;
  camera.zoom = 6;
  // controls.enableZoom = false;
  // controls.enableRotate = false;
  // controls.enablePan = false;
  toggleCamera();
}


let toggleCamera = function () {
  // controls.reset();
  // controls.enableZoom = false;
  // controls.enableRotate = false;
  // controls.enablePan = false;
  controls.enabled = false;
  controls.update();
}

function dollyIn( dollyScale ) {
    if ( camera.type === "OrthographicCamera" ) {
      cameraZoom = cameraZoom + 1
      camera.zoom = cameraZoom;
      camera.updateProjectionMatrix();
      controls.enableZoom = false;
    } else {
      console.warn( 'WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.' );
      controls.enableZoom = false;
    }
  }
  
  function dollyOut( dollyScale ) {
    if ( camera.type === "OrthographicCamera" ) {
      cameraZoom = cameraZoom - 1
      camera.zoom = cameraZoom;
      camera.updateProjectionMatrix();
      controls.enableZoom = false;
    } else {
      console.warn( 'WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.' );
      controls.enableZoom = false;
    }
  }

  function resetZoom() {
    cameraZoom = 5
  }
  
//a data structure we use to instruct 'render' function to eventually update
//scene
const sceneInfo = {
  dirty: false, //means cleanup and load following data
  activeMeshes: [], //the meshes to be loaded
  currentMeshes: [], //thew meshes to be removed
  firstStepMeshes: [],
  superImposeDirty: false,
  removeFirstSteps: false,
  superImposeActive: [],
  step: 0,
};

//set the meshes to display
let setMeshes = function (meshes, isSuperImpose, meshesForFirstStep, n) {
  // console.log("step ", n);
  sceneInfo.activeMeshes = meshes;
  sceneInfo.dirty = true;
  sceneInfo.superImposeActive = meshesForFirstStep;
  sceneInfo.superImposeDirty = !!isSuperImpose;
  sceneInfo.removeFirstSteps = isSuperImpose ? false : true;
  sceneInfo.step = n;
};

let changeSceneBackgroundColor = (canvas, isDarkMode) => {
  const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });

  if (isDarkMode) {
    renderer.setClearColor("#1c1c1c", 1);
  } else {
    renderer.setClearColor("#eeedeb", 1);
  }
};


let initialize = function (canvas, { action }) {
  if (canvas === null) {
    return;
  }
  const renderer = new THREE.WebGLRenderer({ canvas });
  switch (action) {
    case "init-template":
      scene.background = new THREE.Color("#53646a");
      break;
    case "drs-template":
      //background image
      var loader = new THREE.TextureLoader();
      var backgroundTexture = loader.load("../imgs/background4.png");
      scene.background = backgroundTexture;
      break;
    case "smokey-template":
      //background color
      scene.background = new THREE.Color("#989ba5");
      break;
    case "lindor-template":
      //background color
      scene.background = new THREE.Color("#ffffff");
      break;
    case "milkyway-template":
        //background image
        scene.background = new THREE.Color("#eeedeb");
        break;
    case "bounty-template":
        //background image
        scene.background = new THREE.Color("#eeedeb");
        break;
    case "baklava-template":
      //background image
      scene.background = new THREE.Color("#eeedeb");
      break;

    default:
      scene.background = new THREE.Color("#53646a");
  }

  scene.add(camera);
  camera.add(light);

  // //orthogonal camera
  // const width = canvas.clientWidth;
  // const height = canvas.clientHeight;
  // const left = width / -2;
  // const right = width / 2;
  // const top = height / 2;
  // const bottom = height / -2;
  // camera moved to top level
  // camera.position.z = 60;
  // camera.position.y = 15

  //controls
  controls = new OrbitControls(camera, renderer.domElement);
  controls.mouseButtons = { LEFT: THREE.MOUSE.LEFT, RIGHT: THREE.MOUSE.PAN
  };
  controls.touches = {
    ONE: THREE.TOUCH.ROTATE,
  };
  controls.saveState();
  controls.enableZoom = false;

  function resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width = (canvas.clientWidth * pixelRatio) | 0;
    const height = (canvas.clientHeight * pixelRatio) | 0;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  function render() {
    let resizePOS = window.innerWidth < 840 ? 1.5 : 2; // onDesktop = 2 || on mobile = 1.5
    let resizeMOS = window.innerWidth < 840 ? -1.5 : -2; // onDesktop = -2 || on mobile = -1.5
    
    
    // if (resizeRendererToDisplaySize(renderer) ) {
      const pixelRatio = window.devicePixelRatio;
      const canvas = renderer.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
      camera.left = width / resizeMOS;
      camera.right = width / resizePOS;
      camera.top = height / resizePOS;
      camera.bottom = height / resizeMOS;
      renderer.setSize(canvas.clientWidth * pixelRatio | 0, canvas.clientHeight * pixelRatio | 0, false);
      renderer.physicallyCorrectLights = true
      camera.updateProjectionMatrix();
    // }

    if (sceneInfo.removeFirstSteps) {
      //cleanup & lpoad new meshes
      sceneInfo.firstStepMeshes.forEach((m) => scene.remove(m)); //pop out
      sceneInfo.firstStepMeshes = []; //promote active to current
      sceneInfo.superImposeActive = [];
      sceneInfo.superImposeDirty = false;
      sceneInfo.removeFirstSteps = false;
    }

    if (sceneInfo.dirty) {
      //cleanup & load new meshes

      sceneInfo.currentMeshes.forEach((m) => scene.remove(m)); //pop out
      sceneInfo.currentMeshes = sceneInfo.activeMeshes; //promote active to current
      sceneInfo.activeMeshes = [];
      sceneInfo.currentMeshes.forEach((m, i) => {
        m.material.shininess = 0;
        scene.add(m);
      }); //push in
      sceneInfo.dirty = false;
    }

    if (sceneInfo.superImposeDirty) {
      //cleanup & load new meshes
      sceneInfo.firstStepMeshes.forEach((m) => scene.remove(m)); //pop out
      sceneInfo.firstStepMeshes = sceneInfo.superImposeActive; //promote active to current
      sceneInfo.superImposeActive = [];
      sceneInfo.firstStepMeshes.forEach((m) => {
        m.material.color = { r: 0, g: 0, b: 1 };
        m.material.transparent = true;
        m.material.opacity = 0.4;
        m.material.shininess = 0;
        scene.add(m);
      }); //push in
      sceneInfo.superImposeDirty = false;
      sceneInfo.removeFirstSteps = false;
    }
    renderer.render(scene, camera);
    requestAnimationFrame(render);
  }

  requestAnimationFrame(render);
};



export default {
  initializeAndRun: initialize,
  setMeshes: setMeshes,
  frontView: frontView,
  leftView: leftView,
  rightView: rightView,
  upperView: upperView,
  lowerView: lowerView,
  rightRevise: rightRevise,
  leftRevise:  leftRevise,
  frontRevise: frontRevise,
  toggleCamera: toggleCamera,
  changeSceneBackgroundColor: changeSceneBackgroundColor,
  dollyIn: dollyIn,
  dollyOut: dollyOut,
  resetZoom: resetZoom,
  zoomValue: zoomValue,
};
